export default [
  {
    pageid: 'safety-information-indication',
    subNavTitle: 'Important Safety Information and Indication',
    link: '/safety-information-indication',
  },
  {
    pageid: 'terms-of-use',
    subNavTitle: 'Terms of Use',
    link: '/terms-of-use',
  },
  {
    pageid: 'resources',
    subNavTitle: 'Resources',
    link: '/resources',
  },
  {
    pageid: 'resources',
    subNavTitle: 'Opioid Analgesics REMS',
    link: '/rems',
  },
  {
    pageid: 'resources',
    subNavTitle: 'Do Not Sell My Information',
    link: '#',
    id:"clarip-do-not-sell-link",
  },
  {
    pageid: 'pat-resources',
    subNavTitle: 'Resources',
    link: '/resources/',
  },
  {
    pageid: 'pat-resources',
    subNavTitle: 'Patch Application & Rotation Tutorial',
    link: '/resources/how-to-apply-patch/',
  },
  {
    pageid: 'pat-isi',
    subNavTitle: 'Important Safety Information',
    link: '/important-safety-information/',
  },
  {
    pageid: 'site-map-patient',
    subNavTitle: 'Site Map',
    link: '/site-map',
  },
  {
    pageid: 'site-map',
    subNavTitle: 'Site Map',
    link: '/site-map',
  },
];
