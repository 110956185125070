import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import navSchema from "./data";

const SideNav = ({ pageId }) => {
  const [currentSubNavs, setCurrentSubNavs] = useState([]);

  //console.log(navSchema);

  useEffect(() => {
    //console.log('pageId', pageId);
    if (pageId) {
      const result = navSchema.filter((item) => item.pageid == pageId);
      //console.log("result", result);

      setCurrentSubNavs([...result]);
    }
  }, [pageId]);

  return currentSubNavs.length > 0 ? (
    <ul className="side-nav">
      {currentSubNavs.map((item) => (
        <li>
          <Link
            to={item.link}
            activeClassName="active"
            id={item.id}
            partiallyActive={false}
          >
            {item.subNavTitle}
          </Link>
        </li>
      ))}
    </ul>
  ) : null;
};

export default SideNav;
